import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import HinnerJagComponent from '../components/hinner-jag';

import styled from 'styled-components';
import { mixins, Main } from '../styles';

const MainContainer = styled(Main)`
  ${mixins.sidePadding};
  counter-reset: section;
`;

const HinnerJagPage = ({ location }) => (
  <Layout location={location}>
    <MainContainer id="content">
      <HinnerJagComponent />
    </MainContainer>
  </Layout>
);

HinnerJagPage.propTypes = {
  location: PropTypes.object,
};

export default HinnerJagPage;
