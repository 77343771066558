import React, { Component } from 'react';
import ScrollReveal from 'scrollreveal';
import { srConfig, email } from '../config';
import styled from 'styled-components';
import { mixins, Section } from '../styles';

const HinnerJagContainer = styled(Section)`
  position: relative;
`;

const EmailLink = styled.a`
  ${mixins.bigButton};
  margin-top: 50px;
  margin-bottom: 50px;
`;

class HinnerJag extends Component {
  componentDidMount() {
    ScrollReveal().reveal(this.hinnerJagRef, srConfig());
  }

  render() {
    return (
      <HinnerJagContainer id="hinner-jag" ref={el => (this.hinnerJagRef = el)}>
        <h1>Hinner jag? Sthlm</h1>
        <div>
          <p>
            Hinner jag med tunnelbanan? Är det lönt att jag skynda mig? Det är en fråga vi
            Stockholmare ofta ställs inför.
          </p>
          <p>
            Vore det inte bra om man kunder besvara den på ett par sekunder, utan att ens behöva
            låsa upp telefonen?
          </p>
          <p>
            Appen ger dig snabb information om när de närmaste avgångarna går från den tunnelbanan
            som är närmast din nuvarande position.
          </p>
          <p>
            Med hjälp av vår Today widget (fås fram genom att dra ned i skärmens övre ände) kan du
            på ett par sekunder få reda på om det är lönt att skynda sig eller om du kan gå lugnt.
            Du kan även snabbt byta vilken station du vill se avgångar ifrån med ett enkelt klick.
          </p>
        </div>
        <EmailLink
          href={`mailto:${email}?subject=HinnerJag`}
          target="_blank"
          rel="nofollow noopener noreferrer">
          Säg hej
        </EmailLink>
      </HinnerJagContainer>
    );
  }
}

export default HinnerJag;
